import { Grow, MenuList, Paper, Popper, Typography } from "@mui/material"
import { ListItemProps } from "@mui/material/ListItem"
import MenuItem from "@mui/material/MenuItem"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Trans } from "react-i18next"

import PermissionGuard from "@/components/PermissionGuard"
import {
  Item,
  Link,
  MenuDivider,
  MenuHeader,
  MenuHeaderWrapper,
} from "@/components/sidebar/Sidebar.styled"
import { sxMl4 } from "@/constants"
import useUIConfig from "@/hooks/useUIConfig"
import { ModelRights, Models } from "@/shared/policies/types"
import { SidebarMenuType } from "@/types/sidebar"

export type ItemType = {
  activeClassName?: string
  onClick?: () => void
  href?: string
}

export type SidebarNavListItemProps = ListItemProps & {
  className?: string
  href: string
  icon: React.FC<any>
  title: string
  model?: keyof Models
  right?: ModelRights
  menu?: SidebarMenuType[]
}

type MenuItemProps = {
  menuItem: SidebarMenuType
  index: number
  handleMenuClose: () => void
}

const sxZindexDrawer = { zIndex: "drawer" }

const GenerateMenuItem: React.FC<MenuItemProps> = ({
  menuItem,
  index,
  handleMenuClose,
}) => {
  const { getConfigBoolean } = useUIConfig()
  const { title, href, separator, model, right, subMenu } = menuItem

  if (separator) {
    return <MenuDivider key={`separator-${index}`} variant="middle" />
  }

  if (menuItem.moduleEnabledConfig) {
    const moduleEnabled = getConfigBoolean("Base", menuItem.moduleEnabledConfig)
    if (!moduleEnabled) {
      return null
    }
  }

  const menuItemContent = (
    <Link to={href} key={`menu-item--${index}`}>
      <MenuItem onClick={handleMenuClose}>
        <strong>
          <Trans>{title}</Trans>
        </strong>
      </MenuItem>
    </Link>
  )

  const menuContent = model ? (
    <PermissionGuard
      empty
      model={model}
      right={right}
      key={`menu-item-${index}`}
    >
      {menuItemContent}
    </PermissionGuard>
  ) : (
    menuItemContent
  )

  const subMenuContent = subMenu ? (
    <>
      <MenuDivider key={`separator-${index}`} variant="middle" />
      <MenuItem key={`submenu-item-${index}`}>
        <strong>
          <Trans>{title}</Trans>
        </strong>
        <MenuDivider
          variant="middle"
          orientation="vertical"
          flexItem
          sx={sxMl4}
        />
        <MenuList>
          {subMenu.map((menuItem, subIndex) => (
            <GenerateMenuItem
              key={`sub-submenu-item-${subIndex}`}
              menuItem={menuItem}
              index={subIndex}
              handleMenuClose={handleMenuClose}
            />
          ))}
        </MenuList>
      </MenuItem>
    </>
  ) : undefined

  const submenuContainer = subMenu ? (
    model ? (
      <PermissionGuard
        empty
        model={model}
        right={right}
        key={`sub-menu-item-pg-${index}`}
      >
        {subMenuContent}
      </PermissionGuard>
    ) : (
      subMenuContent
    )
  ) : undefined

  return subMenu ? submenuContainer : menuContent
}

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = (props) => {
  const { title, href, icon: Icon, menu } = props

  const [isHover, setIsHover] = useState(false)
  const [isHoverMenu, setIsHoverMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)

  const activeClassName = useMemo(() => {
    return isHover || isHoverMenu ? "active" : undefined
  }, [isHover, isHoverMenu])

  const handleMouseEnterItem = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget)
      setIsHover(true)
    },
    [setAnchorEl, setIsHover],
  )

  const handleMouseLeaveItem = useCallback(() => {
    setIsHover(false)
  }, [setIsHover])

  const handleEnterMenu = useCallback(() => {
    setIsHoverMenu(true)
  }, [setIsHoverMenu])

  const handleMenuClose = useCallback(() => {
    setIsHoverMenu(false)
  }, [setIsHoverMenu])

  useEffect(() => {
    if (!isHoverMenu && !isHover) {
      setAnchorEl(null)
      handleMenuClose()
    }
  }, [isHoverMenu, isHover, handleMenuClose, setAnchorEl])

  const theMenu = useMemo(() => {
    if (!menu) return null

    return (
      <MenuList>
        {menu.map((menuItem, index) => (
          <GenerateMenuItem
            menuItem={menuItem}
            index={index}
            key={index}
            handleMenuClose={handleMenuClose}
          />
        ))}
      </MenuList>
    )
  }, [menu, handleMenuClose])

  return (
    <Link to={href}>
      <Item
        className={activeClassName}
        onMouseEnter={handleMouseEnterItem}
        onMouseLeave={handleMouseLeaveItem}
      >
        {Icon && <Icon />}
      </Item>
      {menu && (
        <Popper
          anchorEl={anchorEl}
          open={openMenu}
          role={undefined}
          placement="right-start"
          transition
          sx={sxZindexDrawer}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper
                onMouseLeave={handleMenuClose}
                onMouseEnter={handleEnterMenu}
              >
                <MenuHeaderWrapper>
                  <MenuHeader>
                    <Typography variant="h5">
                      <Trans>{`Menu.${title}`}</Trans>
                    </Typography>
                    {Icon && <Icon fontSize="small" />}
                  </MenuHeader>
                </MenuHeaderWrapper>
                {theMenu}
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </Link>
  )
}

export default SidebarNavListItem
