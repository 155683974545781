import lodash from 'lodash'
import { ModelRights, Models, RolePolicyModel } from './types.js'
import { models } from './modelPolicies.js'

const orderedRights: ModelRights[] = ['Read', 'Write', 'Create', 'Delete', 'Admin']

const rightsPositions: Record<ModelRights, number> = {
  Read: 0,
  Write: 1,
  Create: 2,
  Delete: 3,
  Admin: 4,
}

const hasRight = (currentRight: ModelRights, requiredRight: ModelRights) => orderedRights.indexOf(currentRight) >= rightsPositions[requiredRight]

export const anySubsetHasRight = (model: keyof RolePolicyModel, right: ModelRights, rolePolicyModel: RolePolicyModel) => {
  const modelPolicies = rolePolicyModel[model]
  if (!modelPolicies) return false

  return lodash.some(
    Object.entries(modelPolicies),
    (p) => p[1].rights && hasRight(p[1].rights, right)
  )
}

export const subsetHasRight = <K extends keyof RolePolicyModel>(model: K, subset: Models[K]["subsets"], right: ModelRights, rolePolicyModel: RolePolicyModel) => {
  const modelPolicies = rolePolicyModel[model]
  if (!modelPolicies) return false

  const subsetPolicies = modelPolicies[subset]
  if (!subsetPolicies) return false

  return subsetPolicies.rights && hasRight(subsetPolicies.rights, right)
}

export const canRead = (model: keyof RolePolicyModel, rolePolicyModel: RolePolicyModel) => {
  return anySubsetHasRight(model, 'Read', rolePolicyModel)
}

export const canWrite = (model: keyof RolePolicyModel, rolePolicyModel: RolePolicyModel) => {
  return anySubsetHasRight(model, 'Write', rolePolicyModel)
}

export const canCreate = (model: keyof RolePolicyModel, rolePolicyModel: RolePolicyModel) => {
  return anySubsetHasRight(model, 'Create', rolePolicyModel)
}

export const canDelete = (model: keyof RolePolicyModel, rolePolicyModel: RolePolicyModel) => {
  return anySubsetHasRight(model, 'Delete', rolePolicyModel)
}

export const canSubsetRead = <K extends keyof RolePolicyModel>(model: K, subset: Models[K]["subsets"], rolePolicyModel: RolePolicyModel) => {
  return subsetHasRight(model, subset, 'Read', rolePolicyModel)
}

export const canSubsetWrite = <K extends keyof RolePolicyModel>(model: K, subset: Models[K]["subsets"], rolePolicyModel: RolePolicyModel) => {
  return subsetHasRight(model, subset, 'Write', rolePolicyModel)
}

export const canSubsetCreate = <K extends keyof RolePolicyModel>(model: K, subset: Models[K]["subsets"], rolePolicyModel: RolePolicyModel) => {
  return subsetHasRight(model, subset, 'Create', rolePolicyModel)
}

export const canSubsetDelete = <K extends keyof RolePolicyModel>(model: K, subset: Models[K]["subsets"], rolePolicyModel: RolePolicyModel) => {
  return subsetHasRight(model, subset, 'Delete', rolePolicyModel)
}

const mergeSubsetRights = (subset: any, mergedSubset: any) => {
  if (!mergedSubset.rights || subset.rights === "Admin") {
    mergedSubset.rights = subset.rights
  }
  else if (subset.rights === "Write" && mergedSubset.rights !== "Create" && mergedSubset.rights !== "Delete" && mergedSubset.rights !== "Admin") {
    mergedSubset.rights = subset.rights
  }
  else if (subset.rights === "Create" && mergedSubset.rights !== "Delete" && mergedSubset.rights !== "Admin") {
    mergedSubset.rights = subset.rights
  }
  else if (subset.rights === "Delete" && mergedSubset.rights !== "Admin") {
    mergedSubset.rights = subset.rights
  }
}

export const mergeSubsetAttributes = (subsetAttributes: any, mergedSubset: any, modelKey: string) => {
  if (!mergedSubset.attributes) {
    mergedSubset.attributes = lodash.cloneDeep(subsetAttributes)
  }
  else {
    if (subsetAttributes === "Write") {
      mergedSubset.attributes = subsetAttributes
    }
    else if (typeof subsetAttributes === 'string' && typeof mergedSubset.attributes !== 'string') {
      for (const attr of Object.keys(models[modelKey])) {
        if (!mergedSubset.attributes[attr]) {
          mergedSubset.attributes[attr] = subsetAttributes
        }
        else if (subsetAttributes === "Write") {
          mergedSubset.attributes[attr] = subsetAttributes
        }
      }
    }
    else if (typeof subsetAttributes !== 'string' && typeof mergedSubset.attributes === 'string')
    {
      const oldRight = mergedSubset.attributes
      mergedSubset.attributes = {}

      for (const attr of Object.keys(models[modelKey])) {
        mergedSubset.attributes[attr] = oldRight
        if (subsetAttributes[attr] === "Write") {
          mergedSubset.attributes[attr] = subsetAttributes[attr]
        }
      }
    }
    else {
      for (const attr of Object.keys(subsetAttributes)) {
        if (!mergedSubset.attributes[attr]) {
          mergedSubset.attributes[attr] = subsetAttributes[attr]
        }
        else if (subsetAttributes[attr] === "Write") {
          mergedSubset.attributes[attr] = subsetAttributes[attr]
        }
      }
    }

    // if (typeof mergedSubset.attributes !== 'string') {
    //   const uniqueRights = lodash.uniq(Object.values(mergedSubset.attributes))
    //   if (uniqueRights.length === 1 && mergedSubset.attributes.length === Object.keys(models[modelKey]).length) {
    //     mergedSubset.attributes = uniqueRights[0]
    //   }
    // }
  }
}

export const mergeSubset = (subset: any, mergedPolicy: any, modelKey: string, subsetKey: string) => {
  if (!mergedPolicy[modelKey][subsetKey]) {
    mergedPolicy[modelKey][subsetKey] = lodash.cloneDeep(subset)
    return
  }
  
  const mergedSubset = mergedPolicy[modelKey][subsetKey]
  mergeSubsetRights(subset, mergedSubset)

  if (subset.attributes) {
    mergeSubsetAttributes(subset.attributes, mergedSubset, modelKey)
  }
}

export const mergePolicy = (mergedPolicy: any, policy: any) => {
  for (const modelKey of Object.keys(policy)) {
    const model = policy[modelKey]
    if (!model) continue

    if (!mergedPolicy[modelKey]) {
      mergedPolicy[modelKey] = lodash.cloneDeep(model)
      continue
    }

    for (const subsetKey of Object.keys(model)) {
      const subset = model[subsetKey]
      if (!subset) continue

      mergeSubset(subset, mergedPolicy, modelKey, subsetKey)
    }
  }
}

export const mergeRolePolicies = (...policies: RolePolicyModel[]): RolePolicyModel => {
  if (!policies.length) throw new Error('Not enough policies to merge')
  const mergedPolicy: any = {}

  for (const policy of (policies as any[])) {
    mergePolicy(mergedPolicy, policy)
  }

  return mergedPolicy as RolePolicyModel
}
