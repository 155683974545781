import CssBaseline from "@mui/material/CssBaseline"
import React from "react"
import { Outlet, useLocation } from "react-router-dom"

import GlobalStyle from "@/components/GlobalStyle"
import {
  AppContent,
  Drawer,
  MainContent,
  Root,
  drawerWidth,
} from "@/components/layouts/DashboardLayout/DashboardLayout.styled"
import LogoOwner from "@/components/logo/LogoOwner"
import Navbar from "@/components/navbar/NavBar"
import dashboardItems from "@/components/sidebar/dashboardItems"
import Sidebar from "@/components/sidebar/Sidebar"
import UiConfig from "@/components/UiConfig"
import { sxP6 } from "@/constants"

const styleWidthDrawerWidth = { style: { width: drawerWidth } }
const logoDisplayExcludedPaths = ["/", "/planning", "/scheduler"]

const DashboardLayout: React.FC = () => {
  const location = useLocation()

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <UiConfig>
        <Drawer>
          <Sidebar
            PaperProps={styleWidthDrawerWidth}
            items={dashboardItems}
            hideFooter
          />
        </Drawer>
        <AppContent>
          <Navbar />
          <MainContent sx={sxP6}>
            <Outlet />
            {!logoDisplayExcludedPaths.includes(location.pathname) && (
              <>
                <br />
                <br />
                <LogoOwner centered={true} />
              </>
            )}
          </MainContent>
        </AppContent>
      </UiConfig>
    </Root>
  )
}

export default DashboardLayout
