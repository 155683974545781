import { Trans } from "react-i18next"

import Alert from "@/components/Alert"
import {
  canShow,
  useAttributesPermissions,
  usePermissionsRight,
  usePermissionsRightSubset,
} from "@/hooks/usePermissions"
import { Models, ModelRights } from "@/shared/policies/types"
import { NestedKeyOf } from "@/types/generics"

type PermissionGuardProps<K extends keyof Models> = {
  model: K
  right: ModelRights
  empty?: boolean
}

function PermissionGuard<K extends keyof Models>({
  model,
  right,
  empty,
  children,
}: React.PropsWithChildren<PermissionGuardProps<K>>) {
  const canRender = usePermissionsRight(model, right)

  if (!canRender) {
    if (empty) {
      console.log(`Hidden ${model} ${right}`)
      return <></>
    }

    console.log(`Severity ${model} ${right}`)
    return (
      <Alert severity="error">
        <Trans>AccessDenied</Trans>
      </Alert>
    )
  }

  return children
}

type PermissionSubsetGuardProps<K extends keyof Models> = {
  model: K
  right: ModelRights
  subset?: Models[K]["subsets"] | null
  empty?: boolean
}

export function PermissionSubsetGuard<K extends keyof Models>({
  model,
  right,
  subset,
  empty,
  children,
}: React.PropsWithChildren<PermissionSubsetGuardProps<K>>) {
  const canRender = usePermissionsRightSubset(model, right, subset)

  if (!canRender) {
    if (empty) {
      console.log(`Hidden ${model}`)
      return <></>
    }

    console.log(`Severity ${model}`)
    return (
      <Alert severity="error">
        <Trans>AccessDenied</Trans>
      </Alert>
    )
  }

  return children
}

// skipcq: JS-0356
type AttributePermissionSubsetGuardProps<K extends keyof Models> = {
  model: K
  attribute: NestedKeyOf<K>
  right: ModelRights
  subset?: Models[K]["subsets"] | null
  empty?: boolean
}

export function AttributePermissionSubsetGuard<K extends keyof Models>({
  model,
  attribute,
  right,
  subset,
  empty,
  children,
}: React.PropsWithChildren<AttributePermissionSubsetGuardProps<K>>) {
  const attributePermissions = useAttributesPermissions("show", model, subset)

  const canRender = canShow(attribute, attributePermissions, right)

  if (!canRender) {
    if (empty) {
      console.log(`Hidden ${model}`)
      return <></>
    }

    console.log(`Severity ${model}`)
    return (
      <Alert severity="error">
        <Trans>AccessDenied</Trans>
      </Alert>
    )
  }

  return children
}

export default PermissionGuard
