import { createContext, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { FrontendConfig } from "@/graphql"
import { readJson } from "@/services/jsonTransform"
import { RolePolicyModel } from "@/shared/policies/types"

export type CustomFieldsConfig = {
  canDisplayAudiences?: boolean
  canDisplayPressRelease?: boolean
  canDisplaySocialMedias?: boolean
  canDisplayNonEditableImages?: boolean
  canDisplayPresenterNotes?: boolean
  canDisplayClarification?: boolean
  canDisplayAfterhours?: boolean
  canDisplayFlexibleDeadline?: boolean
  canDisplayLegalImplications?: boolean
  canDisplayCostCenter?: boolean
  canDisplayBillable?: boolean
  canDisplayWbsCode?: boolean
  canDisplayStudyCode?: boolean
  canDisplayChargeback?: boolean
  canDisplayCertificateRequired?: boolean
  canDisplayMonographRequired?: boolean
  canDisplayFrenchSpanishScreenshots?: boolean
  canDisplayEditablePdfAvailable?: boolean
  canDisplayProjectNumber?: boolean
  canDisplaySla?: boolean
  canDisplayMobile?: boolean
  canDisplayContactViaTeams?: boolean
  canDisplayCostsEstimateRequired?: boolean
  canDisplayRegion?: boolean
  canDisplayNonDiscloseable?: boolean
  canDisplayRequestedPriority?: boolean
  canDisplayRequestedPriorityReason?: boolean
  canDisplayMissedDeadlineReason?: boolean
  canDisplayHumanResource?: boolean
  canDisplayProduct?: boolean
  canDisplayTherapeuticArea?: boolean

  audiencesRequired?: boolean
  pressReleaseRequired?: boolean
  socialMediasRequired?: boolean
  nonEditableImagesRequired?: boolean
  presenterNotesRequired?: boolean
  clarificationRequired?: boolean
  costCenterRequired?: boolean
  wbsCodeRequired?: boolean
  studyCodeRequired?: boolean
  chargebackRequired?: boolean
  certificateRequiredRequired?: boolean
  monographRequiredRequired?: boolean
  frenchSpanishScreenshotsRequired?: boolean
  editablePdfAvailableRequired?: boolean
  projectNumberRequired?: boolean
  slaRequired?: boolean
  mobileRequired?: boolean
  contactViaTeamsRequired?: boolean
  costsEstimateRequiredRequired?: boolean
  regionRequired?: boolean
  nonDiscloseableRequired?: boolean
  requestedPriorityRequired?: boolean
  requestedPriorityReasonRequired?: boolean
  missedDeadlineReasonRequired?: boolean
  humanResourceRequired?: boolean
  productRequired?: boolean
  therapeuticAreaRequired?: boolean
}

type ConfigContextType = {
  setUiConfig: React.Dispatch<React.SetStateAction<FrontendConfig | undefined>>
  setForceRefreshUiConfig: (_: () => () => Promise<void>) => void
  forceRefreshUiConfig: () => () => Promise<void>
  getConfigNumber: (category: string, key: string) => number | undefined
  getConfigString: (category: string, key: string) => string | undefined
  getConfigBoolean: (category: string, key: string) => boolean | undefined
  getConfigEnum: (category: string, key: string) => any | undefined
  getCustomFieldsConfig: () => CustomFieldsConfig
  permissions: RolePolicyModel
  language: string
}

export const ConfigContext = createContext<ConfigContextType | undefined>(
  undefined,
)

type ConfigProviderProps = {
  children: React.ReactNode
}

type ConfigValueType = number | string | boolean

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const { i18n } = useTranslation()
  const [uiConfig, setUiConfig] = useState<FrontendConfig>()

  const [permissions, setPermissions] = useState<RolePolicyModel>({})
  const [language, setLanguage] = useState("en")

  const [forceRefreshUiConfig, setForceRefreshUiConfig] = useState<
    () => () => Promise<void>
  >(() => async () => {})

  const getConfig = useCallback(
    <T extends ConfigValueType>(
      category: string,
      key: string,
    ): T | undefined => {
      const config = uiConfig?.configs?.find(
        (config) => config?.name === category,
      )
      if (!config) return undefined
      return config.values[key] as T
    },
    [uiConfig],
  )

  useEffect(() => {
    if (!uiConfig?.permissions) {
      setPermissions({})
    } else {
      setPermissions(readJson(uiConfig.permissions) as RolePolicyModel)
    }

    if (uiConfig?.language?.iso639_1) {
      i18n.changeLanguage(uiConfig.language.iso639_1)
      setLanguage(i18n.language)
    }
  }, [i18n, uiConfig, setPermissions, setLanguage])

  const getConfigNumber = useCallback(
    (category: string, key: string) => {
      return getConfig<number>(category, key)
    },
    [getConfig],
  )

  const getConfigString = useCallback(
    (category: string, key: string) => {
      return getConfig<string>(category, key)
    },
    [getConfig],
  )

  const getConfigBoolean = useCallback(
    (category: string, key: string) => {
      return getConfig<boolean>(category, key)
    },
    [getConfig],
  )

  const getConfigEnum = useCallback(
    (category: string, key: string) => {
      return getConfig<string>(category, key)
    },
    [getConfig],
  )

  const getCustomFieldsConfig = useCallback(() => {
    const customFieldsConfig: CustomFieldsConfig = {
      canDisplayAudiences: getConfigBoolean("CustomFields", "audiences"),
      canDisplayPressRelease: getConfigBoolean("CustomFields", "pressRelease"),
      canDisplaySocialMedias: getConfigBoolean("CustomFields", "socialMedias"),
      canDisplayNonEditableImages: getConfigBoolean(
        "CustomFields",
        "nonEditableImages",
      ),
      canDisplayPresenterNotes: getConfigBoolean(
        "CustomFields",
        "presenterNotes",
      ),
      canDisplayClarification: getConfigBoolean(
        "CustomFields",
        "clarification",
      ),
      canDisplayAfterhours: getConfigBoolean("CustomFields", "afterhours"),
      canDisplayFlexibleDeadline: getConfigBoolean(
        "CustomFields",
        "flexibleDeadline",
      ),
      canDisplayLegalImplications: getConfigBoolean(
        "CustomFields",
        "legalImplications",
      ),
      canDisplayCostCenter: getConfigBoolean("CustomFields", "costCenter"),
      canDisplayBillable: getConfigBoolean("CustomFields", "billable"),

      canDisplayWbsCode: getConfigBoolean("CustomFields", "wbsCode"),
      canDisplayStudyCode: getConfigBoolean("CustomFields", "studyCode"),
      canDisplayChargeback: getConfigBoolean("CustomFields", "chargeback"),
      canDisplayCertificateRequired: getConfigBoolean(
        "CustomFields",
        "certificateRequired",
      ),
      canDisplayMonographRequired: getConfigBoolean(
        "CustomFields",
        "monographRequired",
      ),
      canDisplayFrenchSpanishScreenshots: getConfigBoolean(
        "CustomFields",
        "frenchSpanishScreenshots",
      ),
      canDisplayEditablePdfAvailable: getConfigBoolean(
        "CustomFields",
        "editablePdfAvailable",
      ),
      canDisplayProjectNumber: getConfigBoolean(
        "CustomFields",
        "projectNumber",
      ),
      canDisplaySla: getConfigBoolean("CustomFields", "sla"),
      canDisplayMobile: getConfigBoolean("CustomFields", "mobile"),
      canDisplayContactViaTeams: getConfigBoolean(
        "CustomFields",
        "contactViaTeams",
      ),
      canDisplayCostsEstimateRequired: getConfigBoolean(
        "CustomFields",
        "costsEstimateRequired",
      ),
      canDisplayRegion: getConfigBoolean("CustomFields", "region"),
      canDisplayNonDiscloseable: getConfigBoolean(
        "CustomFields",
        "nonDiscloseable",
      ),
      canDisplayRequestedPriority: getConfigBoolean(
        "CustomFields",
        "requestedPriority",
      ),
      canDisplayRequestedPriorityReason: getConfigBoolean(
        "CustomFields",
        "requestedPriorityReason",
      ),
      canDisplayMissedDeadlineReason: getConfigBoolean(
        "CustomFields",
        "missedDeadlineReason",
      ),
      canDisplayHumanResource: getConfigBoolean(
        "CustomFields",
        "humanResource",
      ),
      canDisplayProduct: getConfigBoolean("CustomFields", "product"),
      canDisplayTherapeuticArea: getConfigBoolean(
        "CustomFields",
        "therapeuticArea",
      ),

      audiencesRequired: getConfigBoolean("CustomFields", "audiencesRequired"),
      pressReleaseRequired: getConfigBoolean(
        "CustomFields",
        "pressReleaseRequired",
      ),
      socialMediasRequired: getConfigBoolean(
        "CustomFields",
        "socialMediasRequired",
      ),
      nonEditableImagesRequired: getConfigBoolean(
        "CustomFields",
        "nonEditableImagesRequired",
      ),
      presenterNotesRequired: getConfigBoolean(
        "CustomFields",
        "presenterNotesRequired",
      ),
      clarificationRequired: getConfigBoolean(
        "CustomFields",
        "clarificationRequired",
      ),
      costCenterRequired: getConfigBoolean(
        "CustomFields",
        "costCenterRequired",
      ),

      wbsCodeRequired: getConfigBoolean("CustomFields", "wbsCodeRequired"),
      studyCodeRequired: getConfigBoolean("CustomFields", "studyCodeRequired"),
      chargebackRequired: getConfigBoolean(
        "CustomFields",
        "chargebackRequired",
      ),
      certificateRequiredRequired: getConfigBoolean(
        "CustomFields",
        "certificateRequiredRequired",
      ),
      monographRequiredRequired: getConfigBoolean(
        "CustomFields",
        "monographRequiredRequired",
      ),
      frenchSpanishScreenshotsRequired: getConfigBoolean(
        "CustomFields",
        "frenchSpanishScreenshotsRequired",
      ),
      editablePdfAvailableRequired: getConfigBoolean(
        "CustomFields",
        "editablePdfAvailableRequired",
      ),
      projectNumberRequired: getConfigBoolean(
        "CustomFields",
        "projectNumberRequired",
      ),
      slaRequired: getConfigBoolean("CustomFields", "slaRequired"),
      mobileRequired: getConfigBoolean("CustomFields", "mobileRequired"),
      contactViaTeamsRequired: getConfigBoolean(
        "CustomFields",
        "contactViaTeamsRequired",
      ),
      costsEstimateRequiredRequired: getConfigBoolean(
        "CustomFields",
        "costsEstimateRequiredRequired",
      ),
      regionRequired: getConfigBoolean("CustomFields", "regionRequired"),
      nonDiscloseableRequired: getConfigBoolean(
        "CustomFields",
        "nonDiscloseableRequired",
      ),
      requestedPriorityRequired: getConfigBoolean(
        "CustomFields",
        "requestedPriorityRequired",
      ),
      requestedPriorityReasonRequired: getConfigBoolean(
        "CustomFields",
        "requestedPriorityReasonRequired",
      ),
      missedDeadlineReasonRequired: getConfigBoolean(
        "CustomFields",
        "missedDeadlineReasonRequired",
      ),
      humanResourceRequired: getConfigBoolean(
        "CustomFields",
        "humanResourceRequired",
      ),
      productRequired: getConfigBoolean("CustomFields", "productRequired"),
      therapeuticAreaRequired: getConfigBoolean(
        "CustomFields",
        "therapeuticAreaRequired",
      ),
    }
    return customFieldsConfig
  }, [getConfigBoolean])

  const contextValue: ConfigContextType = useMemo(() => {
    return {
      setUiConfig,
      setForceRefreshUiConfig,
      forceRefreshUiConfig,
      getConfigNumber,
      getConfigString,
      getConfigBoolean,
      getConfigEnum,
      getCustomFieldsConfig,
      permissions,
      language,
    }
  }, [
    setUiConfig,
    setForceRefreshUiConfig,
    forceRefreshUiConfig,
    getConfigNumber,
    getConfigString,
    getConfigBoolean,
    getConfigEnum,
    getCustomFieldsConfig,
    permissions,
    language,
  ])

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  )
}
