import React, { useMemo } from "react"

import { loadConfig } from "@/contexts/CognitoContext"

const imgStyle = { maxWidth: "200px", maxHeight: "150px" }

export type LogoOwnerProps = {
  centered?: boolean
}

const LogoOwner: React.FC<LogoOwnerProps> = ({ centered }) => {
  const config = loadConfig()
  const logo = `/static/logo/${config?.logo ?? ""}`

  const imgWrapperStyle = useMemo(() => {
    if (centered) {
      return { display: "flex", justifyContent: "center" }
    } else {
      return {}
    }
  }, [centered])

  return (
    <div style={imgWrapperStyle}>
      <img src={logo} alt="Logo" style={imgStyle} />
    </div>
  )
}

export default LogoOwner
