import { ButtonProps as MuiButtonProps } from "@mui/material/Button"

import MuiButton from "@/components/Button/Button.styled"

type ButtonProps = Omit<React.PropsWithChildren<MuiButtonProps>, "color"> & {
  color?: string
}

const Button: React.FC<ButtonProps> = ({
  type = "button",
  variant = "contained",
  disableElevation = true,
  color = "primary",
  size = "small",
  children = "button",
  ...props
}) => {
  return (
    <MuiButton
      type={type}
      variant={variant}
      disableElevation={disableElevation}
      color={color as any}
      size={size}
      {...props}
      role="button"
    >
      {children}
    </MuiButton>
  )
}

export default Button
