import MuiThemeProvider from "@mui/material/styles/ThemeProvider"
import StyledEngineProvider from "@mui/styled-engine-sc/StyledEngineProvider"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3"
import { LicenseInfo } from "@mui/x-license"
import React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { Provider, ProviderProps } from "react-redux"
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import { ThemeProvider } from "styled-components"

import AuthGuard from "@/components/guards/AuthGuard"
import AuthLayout from "@/components/layouts/AuthLayout"
import DashboardLayout from "@/components/layouts/DashboardLayout"
import Snack from "@/components/Snack"
import { BreadcrumbProvider } from "@/contexts/BreadcrumbContext"
import { AuthProvider } from "@/contexts/CognitoContext"
import { ConfigProvider } from "@/contexts/ConfigContext"
import { GraphqlProvider } from "@/contexts/GraphqlContext"
import { SnackbarProvider } from "@/contexts/SnackbarContext"
import { ThemeProvider as ThemeContextProvider } from "@/contexts/ThemeContext"
import useTheme from "@/hooks/useTheme"
import "@/i18n/config"
import * as Pages from "@/pages"
import { store } from "@/redux/store"
import createTheme from "@/theme"
import { enableFrontendValidation } from "@/validation/validationContext"

import "@/vendor/perfect-scrollbar.css"
import "@bryntum/core-thin/core.material.css"
import "@bryntum/grid-thin/grid.material.css"
import "@bryntum/calendar-thin/calendar.material.css"
import "@bryntum/scheduler-thin/scheduler.material.css"

enableFrontendValidation()

LicenseInfo.setLicenseKey(
  "d45471ae610b9956bba2e4c3495493ecTz0xMDcyMDcsRT0xNzcwMjQ5NTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=",
)

const Guard: React.FC<React.PropsWithChildren<{ path: string }>> = ({
  path,
  children,
}) => {
  if (!path.startsWith(`/auth/`)) {
    return (
      <AuthGuard>
        <GraphqlProvider>{children}</GraphqlProvider>
      </AuthGuard>
    )
  }
  return <>{children}</>
}

const ThemeSwitcher: React.FC = () => {
  const { theme } = useTheme()
  const location = useLocation()

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <ThemeProvider theme={createTheme(theme)}>
        <Guard path={location.pathname}>
          <Outlet />
        </Guard>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

const ReduxProvider: React.FC<ProviderProps> = ({ children, store }) => {
  return <Provider store={store}>{children}</Provider>
}

const AppLayout: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s" defaultTitle="Broca NextGen" />
      <ReduxProvider store={store}>
        <AuthProvider>
          <ConfigProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SnackbarProvider>
                <Snack />
                <BreadcrumbProvider>
                  <StyledEngineProvider injectFirst>
                    <ThemeContextProvider>
                      <ThemeSwitcher />
                    </ThemeContextProvider>
                  </StyledEngineProvider>
                </BreadcrumbProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </ConfigProvider>
        </AuthProvider>
      </ReduxProvider>
    </HelmetProvider>
  )
}

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="auth" element={<AuthLayout />}>
            <Route index element={<Pages.SignIn />} />
            <Route path="signIn" element={<Pages.SignIn />} />
            <Route path="forgotPassword" element={<Pages.ForgotPassword />} />
            <Route path="resetPassword" element={<Pages.ResetPassword />} />
            <Route path="newPassword" element={<Pages.NewPassword />} />
            <Route path="consume" element={<Pages.ConsumeIdpResponse />} />
          </Route>
          <Route path="changePassword" element={<Pages.ChangePassword />} />
          <Route element={<DashboardLayout />}>
            <Route path="activities">
              <Route index element={<Pages.Activities />} />
              <Route path="new" element={<Pages.NewActivity />} />
              <Route path=":id" element={<Pages.ShowActivity />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditActivity />} />
              </Route>
            </Route>
            <Route path="audiences">
              <Route index element={<Pages.Audiences />} />
              <Route path="new" element={<Pages.NewAudience />} />
              <Route path=":id" element={<Pages.ShowAudience />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditAudience />} />
              </Route>
            </Route>
            <Route path="bitexts">
              <Route index element={<Pages.Bitexts />} />
              <Route path="new" element={<Pages.NewBitext />} />
              <Route path=":id" element={<Pages.ShowBitext />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditBitext />} />
              </Route>
            </Route>
            <Route path="bitextImports">
              <Route index element={<Pages.BitextImports />} />
              <Route path="new" element={<Pages.NewBitextImport />} />
              <Route path=":id" element={<Pages.ShowBitextImport />} />
            </Route>
            <Route path="terminoImports">
              <Route index element={<Pages.TerminoImports />} />
              <Route path="new" element={<Pages.NewTerminoImport />} />
              <Route path=":id" element={<Pages.ShowTerminoImport />} />
            </Route>
            <Route index element={<Pages.Home />} />
            <Route path="calendarTemplates">
              <Route index element={<Pages.CalendarTemplates />} />
              <Route path="new" element={<Pages.NewCalendarTemplate />} />
              <Route path=":id" element={<Pages.ShowCalendarTemplate />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditCalendarTemplate />} />
              </Route>
            </Route>
            <Route path="clients" element={<Pages.Clients />} />
            <Route path="clients">
              <Route index element={<Pages.Clients />} />
              <Route path="new" element={<Pages.NewClient />} />
              <Route path=":id" element={<Pages.ShowClient />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditClient />} />
              </Route>
            </Route>
            <Route path="contacts">
              <Route index element={<Pages.Contacts />} />
              <Route path="new" element={<Pages.NewContact />} />
              <Route path=":id" element={<Pages.ShowContact />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditContact />} />
              </Route>
            </Route>
            <Route path="domains">
              <Route index element={<Pages.Domains />} />
              <Route path="new" element={<Pages.NewDomain />} />
              <Route path=":id" element={<Pages.ShowDomain />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditDomain />} />
              </Route>
            </Route>
            <Route path="billingTypes">
              <Route index element={<Pages.BillingTypes />} />
            </Route>
            <Route path="invoiceTypes">
              <Route index element={<Pages.InvoiceTypes />} />
            </Route>
            <Route path="taxSchemas">
              <Route index element={<Pages.TaxSchemas />} />
            </Route>
            <Route path="taxRates">
              <Route index element={<Pages.TaxRates />} />
            </Route>
            <Route path="paymentTypes">
              <Route index element={<Pages.PaymentTypes />} />
            </Route>
            <Route path="invoiceCurrencies">
              <Route index element={<Pages.InvoiceCurrencies />} />
            </Route>
            <Route path="businessLines">
              <Route index element={<Pages.BusinessLines />} />
            </Route>
            <Route path="departments">
              <Route index element={<Pages.Departments />} />
            </Route>
            <Route path="errors">
              <Route index element={<Pages.Errors />} />
              <Route path=":id" element={<Pages.ShowError />} />
            </Route>
            <Route path="humanResources">
              <Route index element={<Pages.HumanResources />} />
              <Route path="new" element={<Pages.NewHumanResource />} />
              <Route path=":id" element={<Pages.ShowHumanResource />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditHumanResource />} />
              </Route>
            </Route>
            <Route path="home" element={<Pages.Home />} />
            <Route path="invoiceFooters">
              <Route index element={<Pages.InvoiceFooters />} />
              <Route path="new" element={<Pages.NewInvoiceFooter />} />
              <Route path=":id" element={<Pages.ShowInvoiceFooter />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditInvoiceFooter />} />
              </Route>
            </Route>
            <Route path="invoices">
              <Route index element={<Pages.Invoices />} />
              <Route path="new" element={<Pages.CreateNewInvoice />} />
              <Route path=":id" element={<Pages.ShowInvoice />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditInvoice />} />
              </Route>
            </Route>
            <Route path="profile">
              <Route index element={<Pages.Profile />} />
              <Route path="edit" element={<Pages.EditProfile />} />
            </Route>
            <Route path="messages">
              <Route index element={<Pages.Messages />} />
              <Route path=":id" element={<Pages.ShowMessage />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditMessage />} />
              </Route>
            </Route>
            <Route path="messageCreateRequest">
              <Route path="show" element={<Pages.ShowMessageCreateRequest />} />
              <Route path="edit">
                <Route
                  path=":id"
                  element={<Pages.EditMessageCreateRequest />}
                />
              </Route>
            </Route>
            <Route path="notifications">
              <Route index element={<Pages.Notifications />} />
              <Route path="new" element={<Pages.NewNotification />} />
              <Route path=":id" element={<Pages.ShowNotification />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditNotification />} />
              </Route>
            </Route>
            <Route path="planning">
              <Route index element={<Pages.ShowPlanning />} />
            </Route>
            <Route path="pressReleases">
              <Route index element={<Pages.PressReleases />} />
              <Route path="new" element={<Pages.NewPressRelease />} />
              <Route path=":id" element={<Pages.ShowPressRelease />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditPressRelease />} />
              </Route>
            </Route>
            <Route path="pretrans">
              <Route path=":id" element={<Pages.ShowPretrans />} />
            </Route>
            <Route path="products">
              <Route index element={<Pages.Products />} />
              <Route path="new" element={<Pages.NewProduct />} />
              <Route path=":id" element={<Pages.ShowProduct />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditProduct />} />
              </Route>
            </Route>
            <Route path="projects">
              <Route index element={<Pages.Projects />} />
              <Route path="new" element={<Pages.NewProject />} />
              <Route path=":id" element={<Pages.ShowProject />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditProject />} />
              </Route>
            </Route>
            <Route path="providers">
              <Route index element={<Pages.ProvidersList />} />
              <Route path="new" element={<Pages.NewProvider />} />
              <Route path=":id" element={<Pages.ShowProvider />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditProvider />} />
              </Route>
            </Route>
            <Route path="reports">
              <Route index element={<Pages.Reports />} />
              <Route path="admin" element={<Pages.AdminReports />} />
              <Route path=":id" element={<Pages.ShowReport />} />
            </Route>
            <Route path="repsegs">
              <Route path=":id" element={<Pages.ShowRepSegs />} />
            </Route>
            <Route path="scheduler">
              <Route index element={<Pages.ShowScheduler />} />
            </Route>
            <Route path="search">
              <Route index element={<Pages.NewSearch />} />
            </Route>
            <Route path="serviceNote">
              <Route path="show" element={<Pages.ShowServiceNote />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditServiceNote />} />
              </Route>
            </Route>
            <Route path="similarities">
              <Route path=":id" element={<Pages.ShowSimilarities />} />
            </Route>
            <Route path="socialMedias">
              <Route index element={<Pages.SocialMedias />} />
              <Route path="new" element={<Pages.NewSocialMedia />} />
            </Route>
            <Route path="tagConfigs">
              <Route index element={<Pages.TagConfigs />} />
              <Route path="new" element={<Pages.NewTagConfig />} />
              <Route path=":id" element={<Pages.ShowTagConfig />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditTagConfig />} />
              </Route>
            </Route>
            <Route path="tags">
              <Route index element={<Pages.Tags />} />
              <Route path=":id" element={<Pages.ShowTag />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditTag />} />
              </Route>
              <Route path="action">
                <Route path=":type/:id" element={<Pages.TagAction />} />
              </Route>
            </Route>
            <Route path="taskExecutions">
              <Route index element={<Pages.TaskExecutions />} />
            </Route>
            <Route path="terminos">
              <Route index element={<Pages.Terminos />} />
              <Route path="new" element={<Pages.NewTermino />} />
              <Route path=":id" element={<Pages.ShowTermino />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditTermino />} />
              </Route>
            </Route>
            <Route path="therapeuticAreas">
              <Route index element={<Pages.TherapeuticAreas />} />
              <Route path="new" element={<Pages.NewTherapeuticArea />} />
              <Route path=":id" element={<Pages.ShowTherapeuticArea />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditTherapeuticArea />} />
              </Route>
            </Route>
            <Route path="requests">
              <Route index element={<Pages.Requests />} />
              <Route path="new" element={<Pages.NewRequest />} />
              <Route path=":id" element={<Pages.ShowRequest />} />
              <Route path=":reqid/:id" element={<Pages.ShowRequestMessage />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditRequest />} />
              </Route>
            </Route>
            <Route path="requestSearch">
              <Route index element={<Pages.AdvancedSearch />} />
            </Route>
            <Route path="services">
              <Route index element={<Pages.Services />} />
              <Route path="new" element={<Pages.NewService />} />
              <Route path=":id" element={<Pages.ShowService />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditService />} />
              </Route>
            </Route>
            <Route path="teams">
              <Route index element={<Pages.Teams />} />
              <Route path="new" element={<Pages.NewTeam />} />
              <Route path=":id" element={<Pages.ShowTeam />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditTeam />} />
              </Route>
            </Route>
            <Route path="users">
              <Route index element={<Pages.Users />} />
              <Route path="new" element={<Pages.NewUser />} />
              <Route path=":id" element={<Pages.ShowUser />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditUser />} />
              </Route>
            </Route>
            <Route path="registrations">
              <Route index element={<Pages.Registrations />} />
            </Route>
            <Route path="settings">
              <Route index element={<Pages.Settings />} />
              <Route path=":id" element={<Pages.EditSettings />} />
            </Route>
            <Route path="workflowTemplates">
              <Route index element={<Pages.WorkflowTemplates />} />
              <Route path="new" element={<Pages.NewWorkflowTemplate />} />
              <Route path=":id" element={<Pages.ShowWorkflowTemplate />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditWorkflowTemplate />} />
              </Route>
            </Route>
            <Route path="workTypes">
              <Route index element={<Pages.WorkTypes />} />
              <Route path="new" element={<Pages.NewWorkType />} />
              <Route path=":id" element={<Pages.ShowWorkType />} />
              <Route path="edit">
                <Route path=":id" element={<Pages.EditWorkType />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Pages.Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
